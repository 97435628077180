import { Button, MenuGroup, Text } from '@chakra-ui/react';
import React, { useContext } from 'react';
import router from 'next/router';
import NavMenu from '../navMenu';
import NavMenuItem from '../NavMenuItem';
import { NavigationContext } from '../../../lib/contexts/NavigationContext';

type NavMenuItemProps = {
  variant?: 'menu' | 'buttons'
}
const ClassBookingNav = ({ variant = 'menu' }: NavMenuItemProps) => {
  const { paths } = useContext(NavigationContext);
  const { pathname } = router;
  const isActive = pathname.indexOf('class-booking') > -1;

  return variant === 'menu' ? (
    <NavMenu title="Class Booking" isActive={isActive}>
      <MenuGroup title="Adult">
        { paths.adultTerms && paths.adultTerms.map((term) => (
          <NavMenuItem key={term.id} label={term.name} url={term.url} />
        ))}
      </MenuGroup>
      <MenuGroup title="Children">
        { paths.childTerms && paths.childTerms.map((term) => (
          <NavMenuItem key={term.id} label={term.name} url={term.url} />
        ))}
      </MenuGroup>
      <MenuGroup title="Private Classes">
        <NavMenuItem label="Enquire now" url="https://ukchinaperformingarts.com/dance-school-london/private-classes" />
      </MenuGroup>
    </NavMenu>
  ) : (
    <>
      <Text fontSize="xl">Adult Courses</Text>
      { paths.adultTerms && paths.adultTerms.map((term) => (
        <Button key={term.id} variant="link" onClick={() => router.push(term.url)}>{term.name}</Button>
      ))}
      <Text fontSize="xl">Child Courses</Text>
      { paths.childTerms && paths.childTerms.map((term) => (
        <Button key={term.id} variant="link" onClick={() => router.push(term.url)}>{term.name}</Button>
      ))}
      <Text fontSize="xl">Private Classes</Text>
      <Button variant="link" onClick={() => router.push('https://ukchinaperformingarts.com/dance-school-london/private-classes')}>Enquire now</Button>
    </>
  );
};

export default ClassBookingNav;
