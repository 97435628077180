import {
  GetSiteTermsDocument,
  IGetSiteTermsQuery,
  ITerm,
} from '../../graphql';
import { initializeApollo } from '../../apollo-client';

const apolloClient = initializeApollo();

interface ISitesTermResponse {
  sites: Array<{ name: string; terms: Array<ITerm> }>
}
const getSiteTerms = async (): Promise<ISitesTermResponse> => {
  try {
    const query = await apolloClient.query({
      query: GetSiteTermsDocument,
      variables: { data: {} },
    });
    const { getSiteTerms: getSiteTermsData }: IGetSiteTermsQuery = query.data;
    const {
      sites,
    } = getSiteTermsData;
    return {
      sites,
    };
  } catch (error) {
    console.error('ERROR', error.networkError.result.errors);
    return {
      sites: [],
    };
  }
};

export default getSiteTerms;
